import { Controller } from 'stimulus';

export default class extends Controller {
    hidden = ["ease-in", "duration-100", "opacity-0", "scale-95"];

    static targets = ['flash', 'button']

    dismiss(event) {
        this.flashTargets.forEach((flash, flashPosition)=>{
            flash.querySelectorAll(':scope [data-action="flash#dismiss"]').forEach((button)=>{
                if(button === event.currentTarget){
                    this.hidden.forEach((value)=>{ flash.classList.toggle(value, true); })
                    this.remove(flashPosition);
                }
            })
        })
    }

    async remove(index) {
        let duration = 100;

        await new Promise(() => {
            setTimeout(() => {
                this.flashTargets[index].classList.toggle("hidden", true);
                this.flashTargets[index].remove();
            }, duration);
        });
    }
}

// controller for flash message ?
// maybe vue ?
// need to be discussed !

