import { Controller } from 'stimulus';

export default class extends Controller {
    hidden = ["ease-in", "duration-150", "opacity-0", "translate-y-1"];
    shown = ["ease-out", "duration-150", "opacity-1", "translate-y-16"];
    clicked = false;

    static targets = ['usermenudropdown'];

    connect() {
        window.addEventListener('click', (event)=>{
            if(this.clicked === false){
                this.dismiss();
            }
            else {
                this.clicked = false;
            }
        });
    }

    toggle(event) {
        let classList = [...this.usermenudropdownTarget.classList];

        if (classList.includes("invisible")) {
            this.usermenudropdownTarget.classList.toggle('invisible', false);
            this.hidden.forEach((value) => {this.usermenudropdownTarget.classList.toggle(value, false);})
            this.shown.forEach((value) => {this.usermenudropdownTarget.classList.toggle(value, true);})

            this.clicked = true;
        }
    }

    dismiss() {
        this.shown.forEach((value) => {this.usermenudropdownTarget.classList.toggle(value, false);})
        this.hidden.forEach((value) => {this.usermenudropdownTarget.classList.toggle(value, true);})

        this.invisible();
    }

    async invisible() {
        let duration = 150;

        await new Promise(() => {
            setTimeout(() => {
                this.usermenudropdownTarget.classList.toggle("invisible", true);
            }, duration);
        });
    }
}