window.addEventListener("DOMContentLoaded", (e) => {
    var toTopButton = document.getElementById("to-top-button");

    // When the user scrolls down 200px from the top of the document, show the button
    window.onscroll = function () {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            toTopButton.classList.remove("hidden");
        } else {
            toTopButton.classList.add("hidden");
        }
    }
    toTopButton.addEventListener("click",(event) =>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },false);
});