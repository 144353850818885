import { Controller } from 'stimulus';

export default class extends Controller {
    hidden = ["ease-in", "duration-150", "opacity-0"];
    shown = ["ease-out", "duration-150", "opacity-1"];
    clicked = false;

    static targets = ['tabledropdown'];

    connect() {
        window.addEventListener('click', (event)=>{
            if(false === this.clicked && this.tabledropdownTarget !== event.target.closest('[data-tabledropdown-target="tabledropdown"]')){
                this.dismiss();
            }
            else {
                this.clicked = false;
            }
        });
    }

    toggle(event) {
        let classList = [...this.tabledropdownTarget.classList];

        if (classList.includes("hidden")) {
            this.tabledropdownTarget.classList.toggle('hidden', false);
            this.hidden.forEach((value) => {this.tabledropdownTarget.classList.toggle(value, false);})
            this.shown.forEach((value) => {this.tabledropdownTarget.classList.toggle(value, true);})

            this.clicked = true;
        }
    }

    dismiss() {
        this.shown.forEach((value) => {this.tabledropdownTarget.classList.toggle(value, false);})
        this.hidden.forEach((value) => {this.tabledropdownTarget.classList.toggle(value, true);})

        this.invisible();
    }

    async invisible() {
        let duration = 150;

        await new Promise(() => {
            setTimeout(() => {
                this.tabledropdownTarget.classList.toggle("hidden", true);
            }, duration);
        });
    }
}
